<template>
  <vs-card>
    <div
      slot:header
      class="flex flex-no-wrap w-full justify-between items-center"
      vs-w="12"
    >
      <h4>Patients</h4>
      <div class="mr-2">
        <!-- <vs-button
          v-if="canAssignClinic"
          @click="onboardNurseHandler"
          icon-pack="feather"
          icon="icon-edit"
          >Add Clinic</vs-button
        > -->
        <!-- <vs-button @click="redirectToCreateClinic">Create</vs-button> -->
      </div>
      <!-- <div
              class="mr-2"
              v-if="
                $store.state.AppActiveUser.userType === 'superAdmin' ||
                ($store.state.AppActiveUser.userType === 'admin' &&
                  canCreate &&
                  !franchiseId)
              "
            >
              <vs-button
                @click="onboardNurseHandler"
                icon-pack="feather"
                icon="icon-edit"
                >Onboard Nurse</vs-button
              >
            </div> -->
    </div>
    <div>
      <div id="data-list-list-view" class="data-list-container">
        <div class="lg:hidden mt-3 patientlist-mobile">
          <div class="mb-2">
            <div class="w-full pr-1 flex flex-no-wrap">
              <vs-input
                class="px-1"
                icon="search"
                style="width: 70%"
                placeholder="Search"
                v-model="searchInputString"
              />
              <vs-select
                class="px-1"
                width="30%"
                placeholder="10"
                autocomplete
                v-model="dataTableParams.limit"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in limitOptions"
                />
              </vs-select>
              
            <div class="export-container">
              <vs-button @click="exportPatientsList()">Export</vs-button>
            </div>
            </div>
            <!-- <div class="pl-1" style="width: 14.5rem">
            <vs-select
              v-model="dataTableParams.filter"
              placeholder="All Nurses"
              autocomplete
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.label"
                v-for="(item, index) in clinics"
              />
            </vs-select>
          </div> -->
            <!-- <div class="w-24 pl-1">
              
            </div> -->
            <div class="pr-1">
              <vs-select
                v-model="dataTableParams.clinicId"
                placeholder="All Clinic"
                autocomplete
                class="mr-6 p-1 w-full"
              >
                <vs-select-item
                  :key="index"
                  :value="item.clinicId"
                  :text="item.name"
                  v-for="(item, index) in clinics"
                />
              </vs-select>
            </div>
          </div>
          <ul>
            <!-- <li :key="indextr" v-for="(tr, indextr) in users">
            <div
              class="rounded mb-2 p-2 mobile-text"
              style="border: 1px solid #e8e8e8"
            >
              <div class="flex">
                <h4 class="mr-2 mobile-text">
                  {{
                    users[indextr].orgname
                      ? users[indextr].orgname
                      : "N/A" | capitalize
                  }}
                </h4>
                <br>
                <h4 class="mobile-text">
                  {{
                    users[indextr].role
                      ? users[indextr].role
                      : "N/A" | capitalize
                  }}
                </h4>
              </div>
              <div>
                <p>
                  {{ users[indextr].adress }}
                </p>
              </div>
              <div class="flex">
                  <vx-tooltip text="Remove Organization">
                <vs-button style="float: left" size="small" class="m-1">
                  Remove</vs-button
                >
              </vx-tooltip>
                <vx-tooltip
                  v-if="check_has_permission('updateNurseManagement')"
                  text="Edit Nurse Details"
                >
                  <vs-button
                    style="float: left"
                    type="border"
                    size="small"
                    @click="editDetailHandler(users[indextr]._id)"
                    icon-pack="feather"
                    icon="icon-edit"
                    color="success"
                    class="m-1"
                  ></vs-button>
                </vx-tooltip>
              </div>
            </div>
          </li> -->
          </ul>
        </div>
        <vs-table
          class="hidden lg:block patientlist-desktop"
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="users"
          @search="search"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
          search
          :noDataText="noDataText"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse flex-grow justify-between"
          >
            <div>
              <vs-select
                v-model="dataTableParams.clinicId"
                placeholder="All Clinic"
                autocomplete
                class="mr-6"
              >
                <vs-select-item
                  :key="index"
                  :value="item.clinicId"
                  :text="item.name"
                  v-for="(item, index) in clinics"
                />
              </vs-select>
            </div>
            <div class="export-container">
              <vs-button @click="exportPatientsList()">Export</vs-button>
            </div>
          </div>

          <template slot="thead">
            <vs-th sort-key="clinicName">Full name</vs-th>
            <vs-th sort-key="clinicName">Email</vs-th>
            <vs-th sort-key="clinicName">Contact #</vs-th>
            <vs-th sort-key="address">Address</vs-th>
            <vs-th>Actions</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].fullName">{{
                data[indextr].fullName | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr].email">{{
                data[indextr].email
              }}</vs-td>
              <vs-td :data="data[indextr].contactNumber">{{
                data[indextr].contactNumber | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr].address">{{
                data[indextr].address | capitalize
              }}</vs-td>
              <!-- <vs-td :data="data[indextr].role">{{ data[indextr].role }}</vs-td> -->
              <vs-td :data="data[indextr]._id">
                <!-- v-if="check_has_permission('updateNurseManagement')" -->
                <!-- <vx-tooltip text="Edit Patient Details"> -->
                <vs-button
                  style="float: left"
                  size="small"
                  type="border"
                  class="m-1"
                  @click="redirectToPatientEdit(data[indextr]._id)"
                >
                  View</vs-button
                >
                <!-- </vx-tooltip> -->
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <!-- peter -->
        <ul class="block lg:hidden">
          <li :key="indextr" v-for="(tr, indextr) in users">
            <div
              class="rounded mb-2 p-2 mobile-text relative"
              style="border: 1px solid #e8e8e8"
              @click="redirectToPatientEdit(users[indextr]._id)"
            >
              <div>
                <h4 class="p-2 mobile-text">
                  {{
                    users[indextr].fullName
                      ? users[indextr].fullName
                      : "N/A" | capitalize
                  }}
                </h4>
              </div>
              <div v-if="users[indextr].email" class="p-2 mobile-text">
                <p>{{ users[indextr].email }}</p>
              </div>
              <div class="p-2 mobile-text">
                <p>{{ users[indextr].contactNumber }}</p>
              </div>
            </div>
          </li>
        </ul>
        <div
          class="
            m-2
            flex
            sm:justify-between
            justify-center
            items-center
            flex-wrap
          "
          style="height: 35px padding-bottom: 30px"
        >
          <span class="mr-2">
            {{
              dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
            }}
            -
            {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="5"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "organization-table",
  components: {},
  props: {
    canDelete: {
      type: Boolean,
      default: false,
    },
    viewRoute: {
      type: String,
      default: "FranchiseNurseDetails",
    },
    viewDetailRoute: {
      type: String,
      default: "OrgOwnerPatientDetail",
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    createRoute: {
      type: String,
      default: "FranchiseNurseCreate",
    },
    canAssignClinic: {
      type: Boolean,
      default: true,
    },
    franchiseId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      noDataText: "Loading..",
      orgCount: 0,
      totalDocs: 0,
      currentPage: 1,
      searchInputString: null,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        clinicId: null,
      },
      users: [],
      serverResponded: false,
      limitOptions: [5, 10, 25, 50, 100],
      clinics: [{ clinicId: null, name: "All Clinics" }],
      awaitingSearch: null,
    };
  },
  methods: {
    ...mapActions("orgOwner", ["getPatients", "exportPatients"]),
    ...mapActions("organization", ["fetchOrganizationAllClinics"]),
    search(searching) {
      this.searchInputString = searching;
    },
    moment(date) {
      return moment(date);
    },
    async getOrganizationPatientList() {
      this.$vs.loading();
      if (this.dataTableParams.status == "all")
        this.dataTableParams.status = "";
      try {
        const res = await this.getPatients(this.dataTableParams);
        this.$vs.loading.close();
        this.users = res.data.data.docs;
        this.totalDocs = res.data.data.pagination.total;
        this.page = res.data.data.pagination.page;
        this.serverResponded = true;
        this.noDataText = "No patients available";
      } catch (err) {
        console.log("This is the error ::", err);
      }
    },
    getOrganizationAllClinicsList(id) {
      this.fetchOrganizationAllClinics(this.dataTableParams.id).then((res) => {
        this.$vs.loading.close();
        this.clinics = [
          { clinicId: null, name: "All Clinics" },
          ...res.data.data,
        ];
        this.noDataText = "No Clinics Available";
      });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getOrganizationPatientList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getOrganizationPatientList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getOrganizationPatientList();
    },
    redirectToPatientEdit(id) {
      this.$router.push({
        name: this.viewDetailRoute,
        params: {
          patientId: id,
        },
      });
    },
    exportPatientsList() {
      this.$vs.loading();
      const payload = {
        org: [this.dataTableParams.id],
        clinic: this.dataTableParams.clinicId === null ? [] : [this.dataTableParams.clinicId]
      }
      this.exportPatients(payload).then((response)=> {
        const blob = new Blob([response.data], { type: 'text/csv' });

        const downloadUrl = window.URL.createObjectURL(blob);
        const downloadElement = document.createElement('a');
        downloadElement.href = downloadUrl;

        const contentDisposition = response.headers['content-disposition'];
        let filename = 'patients.csv';
        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/attachment; filename="?(.+)"?/);
            if (filenameMatch.length === 2) {
                filename = filenameMatch[1];
            }
        }

        downloadElement.setAttribute('download', filename);
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(downloadUrl);
        this.$vs.notify({
          title: "Export Succesful",
          text: "Patient Records have been downloaded to your device",
          color: "success",
        });
        
        this.$vs.loading.close();
      }).catch((err) => {
          console.error("##ERROR: ", err);
          let errorMsg = "Please try again, or contact support@freshclinics.com"
          if(err.status == 404) {
            errorMsg = "No results found"
          }
          this.$vs.notify({
              title: "Patient Export Unsuccesful",
              text: errorMsg,
              color: 'warning'
          })
          
        this.$vs.loading.close();
      });
    }
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    nurseDetails: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    searchInputString: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    "dataTableParams.clinicId": function (newVal, oldVal) {
      this.getOrganizationPatientList();
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        // this.getNursesList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        // this.getNursesList();
      }
    },
    // "dataTableParams.filter": function (newfilterVal, oldFilterVal) {
    //   if (newfilterVal !== oldFilterVal) {
    //     this.serverResponded = false;
    //     this.dataTableParams.page = 1;
    //     this.dataTableParams.filter = newfilterVal;
    //     // this.getNursesList();
    //   }
    // },
    // "$store.state.AppActiveClinicId": function (newVal, oldVal) {
    //   if (newVal !== oldVal) {
    //     this.dataTableParams.activeClinicId = newVal;
    //     // this.getNursesList();
    //   }
    // },
  },
  async created() {
    // this.dataTableParams.activeClinicId = await this.$store.state
    //   .AppActiveClinicId;
    // if (this.franchiseId && this.franchiseId !== "") {
    //   this.dataTableParams.franchiseId = this.franchiseId;
    // }
    // this.getNursesList();
    // const user = JSON.parse(
    //   localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    // );
    this.dataTableParams.id = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
    // this.dataTableParams.id = this.$route.params.organizationId;
    this.getOrganizationAllClinicsList();
    this.getOrganizationPatientList();
  },
};
</script>
<style scoped>
.mobile-text {
  color: #164d3d;
}
</style>
<style>
.patientlist-desktop .export-container {
  position: absolute;
  right: 43px;
    margin-bottom: 3px;
}
.patientlist-desktop .vs-table--search {
  right: 80px;
}
.patientlist-mobile .export-container {
  margin-top: 2px;
}
</style>